<template>
    <div >
      <v-row>
        <v-col><div style="float:right;font-size:var(--font16);margin-right:-10px" >{{ pageInfoText }}</div></v-col>
        </v-row>
      <div  style="margin-top:15px;font-size:var(--font16);">

      <v-row style="float:right;justify-content: space-between;font-size:var(--font16)">

      <div class="pagination2">
        <div class="rowAlign">
        <span class="pr-2" style="margin-top: 5px;">
          <label class="">
            {{ $t("Show")}}
          </label>
        </span>
        <v-select style="max-width: 100px !important;" :value="rowPerPage" :items="rowPerPageList"
          :placeholder="$t('Limit')" class="form-input rowPerPage" dense persistent-hint outlined hide-details
          @change="updatePage($event)">
          <template v-slot:item="{ item }">
            {{ `${item}` }}
          </template>
          <template v-slot:selection="{ item }">
            <span class="selection-v-select"> {{ `${item}` }}</span>
          </template>
        </v-select>
        <p class="pl-1 mr-6">
          <label class="">
            {{ $t("Rows")}}
          </label>
        </p>
    </div>
        <!-- <div class="d-flex" style="align-items: center !important;"> -->
          <button @click="goTenPageBackwards(pageIndexValue, paging)" :class="pageIndexValue <= 2 ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-prev" type="button">
            <img src="@/assets//img/ico-paging-prev.png" alt="이전 10페이지 이동" />
          </button>
          <v-pagination v-model="pageIndexValue" @input="paging" :length="totalPages * 1 || 1" :total-visible="7"
            color="#2f3b4c" style="display: block" class="pageAlign"></v-pagination>
          <button @click="goTenPageForward(pageIndexValue, paging, totalPages)"
            :class="pageIndexValue + 2 > totalPages ? 'pagination-nav-disabled' : null"
            class="pagination-nav pagination-nav-next" type="button">
            <img src="@/assets//img/ico-paging-next.png" alt="다음 10페이지 이동" />
          </button>
        <!-- </div> -->
        <div class="rowAlign">
        <p class="pr-1 ml-6">
          <label class="">
            {{ $t("Go to")}}
          </label>
        </p>
          <v-text-field v-model="goToPageInput" class="form-input  goToPage"  :placeholder= "$t('Page')" outlined
            dense hide-details solo @keyup="goToPage(goToPageInput, paging, totalPages)"
           @keypress="checkIsNumber($event)"></v-text-field>
        </div>
      </div>
      </v-row>
      </div>
    </div>
  </template>

<script>
// Mixins
import Pagenation from '@/mixins/Pagenation'
import commons from '@/plugins/commons.js'
export default {
  name: 'CustomPagination',
  mixins: [Pagenation],
  props: {
    activePage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 1
    },
    pageInfoText: {
      type: String,
      default: ''
    },
    // goToPageInput: {
    //   type: Number,
    //   default: 1
    // },
    rowPerPage: {
      type: String,
      default: '10'
    },
    pageIndex: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      page: 0,
      goToPageInput: '',
      pageIndexValue: 1,
      // showPerPage: '10',
      rowPerPageList: ['5', '10', '20', '50', '100']
    }
  },
  watch: {
    activePage (val) {
      this.page = val
    },
    goToPageInput (val) {
      this.goToPageInput = Number(val)
    },
    pageIndex () {
      this.pageIndexValue = this.pageIndex
    }
    // showPerPage: {
    //   handler (val) {
    //     this.$emit('update-table-size', Number(val))
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  computed: {
  },
  methods: {
    checkIsNumber: commons.isNumber,
    updatePage (event) {
      this.$emit('updatePage', event)
    },
    paging (e) {
      console.log('check' + e)
      this.goToPageInput = e
      this.$emit('paging', e)
    }
    // gotToPage (goToPageInput, paging, totalPages) {
    //   this.$emit('gotToPage', { goToPageInput: goToPageInput, paging: paging, totalPages: totalPages })
    // }
  }
}
</script>

  <style scoped>
  ::v-deep .pageAlign{
  /* width:100px;
  margin-left:40px;
  margin-right:90px */
  display: flex;
    align-items: center;
    justify-content: center;
}
::v-deep .rowAlign{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
::v-deep .pageAlign ul li {
    padding: 0px !important;
    text-align: center;
}
  ::v-deep .pagination2 .rowPerPage .v-input__slot {
    width: 60px !important;
    min-height: 32px !important;
    margin-top: 3px;
    padding: 0px 1px 0px 7px !important;
  }
  ::v-deep.form-input.v-text-field--outlined {
    padding-top: 0px !important
  }
  ::v-deep .pagination2 .rowPerPage .v-input__slot .v-select__selections {
    padding: 0 !important;
  }

  ::v-deep .pagination2 .goToPage .v-input__slot {
    width: 60px !important;
    /* margin-right: 9px !important; */
    min-height: 32px !important;
    margin-top: 3px;
    text-align: center;
  }

  ::v-deep .pagination2 .rowPerPage .v-input__slot .v-input__icon {
    align-items: end !important;
  }

  ::v-deep.v-select>.v-input__control>.v-input__slot {
    background-color: #ffff !important;
    height: auto !important;
    border-radius: 5px !important;
    padding: 10px;
  }

  ::v-deep.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
    max-height: none;
  }

  ::v-deep.v-text-field.v-text-field--solo .v-input__control {
    min-height: none;
  }
  ::v-deep.v-application .ml-1{
    margin-left: 0px !important;
  }
  ::v-deep.v-text-field.v-text-field--solo .v-input__control input{
    text-align: center !important;
  }
  ::v-deep.mdi-menu-down::before{
    content: "\f140" !important;
  }
  ::v-deep.mdi-menu-down::after{
    content: "\f143" !important;
  }
  ::v-deep .v-pagination .v-pagination__navigation {
    display: none;
}
  </style>
