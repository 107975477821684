var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              {
                staticStyle: {
                  float: "right",
                  "font-size": "var(--font16)",
                  "margin-right": "-10px"
                }
              },
              [_vm._v(_vm._s(_vm.pageInfoText))]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px", "font-size": "var(--font16)" } },
        [
          _c(
            "v-row",
            {
              staticStyle: {
                float: "right",
                "justify-content": "space-between",
                "font-size": "var(--font16)"
              }
            },
            [
              _c(
                "div",
                { staticClass: "pagination2" },
                [
                  _c(
                    "div",
                    { staticClass: "rowAlign" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "pr-2",
                          staticStyle: { "margin-top": "5px" }
                        },
                        [
                          _c("label", {}, [
                            _vm._v(" " + _vm._s(_vm.$t("Show")) + " ")
                          ])
                        ]
                      ),
                      _c("v-select", {
                        staticClass: "form-input rowPerPage",
                        staticStyle: { "max-width": "100px !important" },
                        attrs: {
                          value: _vm.rowPerPage,
                          items: _vm.rowPerPageList,
                          placeholder: _vm.$t("Limit"),
                          dense: "",
                          "persistent-hint": "",
                          outlined: "",
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.updatePage($event)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [_vm._v(" " + _vm._s("" + item) + " ")]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticClass: "selection-v-select" },
                                  [_vm._v(" " + _vm._s("" + item))]
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("p", { staticClass: "pl-1 mr-6" }, [
                        _c("label", {}, [
                          _vm._v(" " + _vm._s(_vm.$t("Rows")) + " ")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "pagination-nav pagination-nav-prev",
                      class:
                        _vm.pageIndexValue <= 2
                          ? "pagination-nav-disabled"
                          : null,
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goTenPageBackwards(
                            _vm.pageIndexValue,
                            _vm.paging
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets//img/ico-paging-prev.png"),
                          alt: "이전 10페이지 이동"
                        }
                      })
                    ]
                  ),
                  _c("v-pagination", {
                    staticClass: "pageAlign",
                    staticStyle: { display: "block" },
                    attrs: {
                      length: _vm.totalPages * 1 || 1,
                      "total-visible": 7,
                      color: "#2f3b4c"
                    },
                    on: { input: _vm.paging },
                    model: {
                      value: _vm.pageIndexValue,
                      callback: function($$v) {
                        _vm.pageIndexValue = $$v
                      },
                      expression: "pageIndexValue"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "pagination-nav pagination-nav-next",
                      class:
                        _vm.pageIndexValue + 2 > _vm.totalPages
                          ? "pagination-nav-disabled"
                          : null,
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.goTenPageForward(
                            _vm.pageIndexValue,
                            _vm.paging,
                            _vm.totalPages
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets//img/ico-paging-next.png"),
                          alt: "다음 10페이지 이동"
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "rowAlign" },
                    [
                      _c("p", { staticClass: "pr-1 ml-6" }, [
                        _c("label", {}, [
                          _vm._v(" " + _vm._s(_vm.$t("Go to")) + " ")
                        ])
                      ]),
                      _c("v-text-field", {
                        staticClass: "form-input  goToPage",
                        attrs: {
                          placeholder: _vm.$t("Page"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          solo: ""
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.goToPage(
                              _vm.goToPageInput,
                              _vm.paging,
                              _vm.totalPages
                            )
                          },
                          keypress: function($event) {
                            return _vm.checkIsNumber($event)
                          }
                        },
                        model: {
                          value: _vm.goToPageInput,
                          callback: function($$v) {
                            _vm.goToPageInput = $$v
                          },
                          expression: "goToPageInput"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }